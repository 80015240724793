'use client'

import { useEffect, useState, useRef } from 'react'
import { Briefcase, Users, Globe } from 'lucide-react'

const useCounter = (end: number, duration: number = 2000, start: boolean) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (!start) return; // Do not start counting if not visible

    let startTime: number | null = null
    const animateCount = (timestamp: number) => {
      if (!startTime) startTime = timestamp
      const progress = timestamp - startTime
      const percentage = Math.min(progress / duration, 1)
      setCount(Math.floor(percentage * end))

      if (percentage < 1) {
        requestAnimationFrame(animateCount)
      }
    }
    requestAnimationFrame(animateCount)
  }, [end, duration, start])

  return count
}

const CounterItem = ({ icon: Icon, value, label, start }: { icon: React.ElementType, value: number, label: string, start: boolean }) => {
  const count = useCounter(value, 2000, start)
  return (
    <div className="col-md-4 mb-4">
      <div className="counter-card">
        <Icon size={48} className="counter-icon" />
        <h2 className="counter-value">{count}</h2>
        <p className="counter-label">{label}</p>
      </div>
    </div>
  )
}

export const Counter = () => {
  const [isVisible, setIsVisible] = useState(false)
  const counterRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.disconnect() // Stop observing after it becomes visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    )

    if (counterRef.current) {
      observer.observe(counterRef.current)
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current)
      }
    }
  }, [])

  return (
    <div ref={counterRef} className="amazing-counter-container">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <h2 className="text-center mb-5 counter-title">Our Achievements</h2>
            <div className="row">
              <CounterItem icon={Briefcase} value={500} label="Projects Completed" start={isVisible} />
              <CounterItem icon={Users} value={250} label="Happy Clients" start={isVisible} />
              <CounterItem icon={Globe} value={30} label="Countries Served" start={isVisible} />
            </div>
          </div>
        </div>
      </div>
      <style jsx global>{`
        .amazing-counter-container {
          background: linear-gradient(135deg, #ffa500, #ff8c00);
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
        .counter-title {
          font-size: 2.5rem;
          font-weight: 700;
          margin-bottom: 3rem;
          text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
        }
        .counter-card {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 15px;
          padding: 2rem;
          text-align: center;
          transition: all 0.3s ease;
          backdrop-filter: blur(10px);
          box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
        }
        .counter-card:hover {
          transform: translateY(-10px);
          box-shadow: 0 12px 48px rgba(0, 0, 0, 0.2);
        }
        .counter-icon {
          margin-bottom: 1rem;
          animation: fadeIn 0.5s ease-in-out;
        }
        .counter-value {
          font-size: 3rem;
          font-weight: bold;
        }
        .counter-label {
          font-size: 1.2rem;
          color: #000;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  )
}